export const FOOTER_BANNER_STATUS = 'footerBanner';

const initialState = {
  footerStatus: null,
};

const footerBannerReducer = (state = initialState, action) => {
  if (action.type === FOOTER_BANNER_STATUS) {
    return {
      ...state,
      footerStatus: {
        content: action.value.content,
        callUsText: action.value.callUsText,
        callActionType: action.value.callActionType,
      },
    };
  }

  return state;
};

export const saveFooterBanner = (footerStatus) => {
  return {
    type: FOOTER_BANNER_STATUS,
    value: footerStatus,
  };
};

export default footerBannerReducer;
